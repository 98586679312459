import axios from 'axios';
import jwt_decode from 'jwt-decode';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';
import {setToken} from "../axios";
import { setLoginData, setKYC, setGKyc, toggleStatusModal, setLoader, setKYB, setGKyb } from '../actions/Auth';

/*========== LOGIN FUNCTIONS =============*/

function* login({ payload, history }) {
  const { error, response } = yield call(postCall, { path: '/loginAdmin', payload });
  if (error) {
    yield put({ type: "TOGGLE_LOGIN" });
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    const decoded = jwt_decode(response["data"]["body"]["token"]);
    if (decoded["role"] !== "admin") {
      EventBus.publish("error", "Can't login through User account ");
      return;
    }
    setToken(response["data"]["body"]["token"]);
    yield put(setLoginData(response["data"]["body"]["token"]));
    yield put({ type: "TOGGLE_LOGIN" });
    EventBus.publish("success", response['data']['message'])
    // setTimeout(() => history.push('/home'), 1000);
  }
};


/*========== KYC FUNCTIONS =============*/

function* getKYC() {
  const { error, response } = yield call(getCall, '/kycApproved');
  if (error){ 
    yield put(setLoader({status:false}));
    EventBus.publish('error', error['response']['data']['message']);
  }
  else if (response) {
    yield put(setLoader({status:false}));
    yield put(setKYC(response['data']['body']));
  }
};

function* kycGlobal() {
  const { error, response } = yield call(getCall, '/kycGlobal');
  if (error) {
    yield put(setLoader({status:false}));
    EventBus.publish('error', error['response']['data']['message']);
  }
  else if (response) {
    yield put(setGKyc(response['data']['body']));
    yield put(setLoader({status:false}));
  }
};

export function* updateKYC({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/kycUpdate",
    payload,
  });
  if (error) {
    yield put(setLoader({status:false}));
    EventBus.publish('error', error['response']['data']['message']);
  } else if (response) {
    yield put(toggleStatusModal(false));
    yield put({ type: "GET_KYC" });
    EventBus.publish("success", response["data"]["message"]);
  }
}

export function* setApprovedUser({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/setApprovedUser",
    payload,
  });
  if (error) {
    yield put(setLoader({status:false}));
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(setLoader({status:false}));
    yield put(setGKyc(response['data']['body']));
    EventBus.publish("success", response["data"]["message"]);
  }
}

export function* deleteUser({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/deleteUser",
    payload,
  });
  if (error) {
    yield put(setLoader({status:false}));
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(setLoader({status:false}));
    yield put(setGKyc(response['data']['body']));
    EventBus.publish("success", response["data"]["message"]);
  }
}


/*========== KYB FUNCTIONS =============*/

function* getKYB() {
  const { error, response } = yield call(getCall, '/getPendingKyb');
  if (error){ 
    yield put(setLoader({status:false}));
    EventBus.publish('error', error['response']['data']['message']);
  }
  else if (response) {
    yield put(setLoader({status:false}));
    yield put(setKYB(response['data']['body']));
  }
};

function* kybGlobal() {
  const { error, response } = yield call(getCall, '/kybGlobal');
  if (error) {
    yield put(setLoader({status:false}));
    EventBus.publish('error', error['response']['data']['message']);
  }
  else if (response) {
    yield put(setGKyb(response['data']['body']));
    yield put(setLoader({status:false}));
  }
};

export function* updateKYB({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/kybUpdate",
    payload,
  });
  if (error) {
    yield put(setLoader({status:false}));
    EventBus.publish('error', error['response']['data']['message']);
  } else if (response) {
    yield put(toggleStatusModal(false));
    yield put({ type: "GET_KYB" });
    EventBus.publish("success", response["data"]["message"]);
  }
}

export function* setApprovedKYB({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/setApprovedKYB",
    payload,
  });
  if (error) {
    yield put(setLoader({status:false}));
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(setLoader({status:false}));
    yield put(setGKyb(response['data']['body']));
    EventBus.publish("success", response["data"]["message"]);
  }
}

export function* deleteKYB({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/deleteKYB",
    payload,
  });
  if (error) {
    yield put(setLoader({status:false}));
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(setLoader({status:false}));
    yield put(setGKyb(response['data']['body']));
    EventBus.publish("success", response["data"]["message"]);
  }
}

function* uploadMetadata({ payload }) {
  console.log("******* payload",payload);
  const { error, response } = yield call(postCall, { path: '/uploadMetadataKyb', payload });
  if (error) {
    yield put(setLoader({ status: false }));
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(setLoader({ status: false }));
  }
};

function* actionWatcher() {
  yield takeEvery('LOGIN', login);
  yield takeEvery('GET_KYC', getKYC);
  yield takeEvery('KYC_GLOBAL', kycGlobal);
  yield takeEvery('UPDATE_KYC', updateKYC);
  yield takeEvery('SET_APPROVED_USER', setApprovedUser);
  yield takeEvery('DELETE_USER', deleteUser);

  yield takeEvery('GET_KYB', getKYB);
  yield takeEvery('KYB_GLOBAL', kybGlobal);
  yield takeEvery('UPDATE_KYB', updateKYB);
  yield takeEvery('SET_APPROVED_KYB', setApprovedKYB);
  yield takeEvery('DELETE_KYB', deleteKYB);

  yield takeEvery('UPLOAD_METADATA', uploadMetadata);
  
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
