const { web3 } = require('../web3');
const { env } = require('../config');

const AdminWhitelistData = require(`./${env}/AdminWhitelist.json`);
const GreenCrossWhitelistData = require(`./${env}/GreenCrossWhitelist.json`);
const CIFIGIVEWhitelistData = require(`./${env}/CIFIGIVEWhitelist.json`);
const SubscriptionData = require(`./${env}/Subscription.json`);
const TokenData = require(`./${env}/SoulBondToken.json`);
const SPVData = require(`./${env}/SPVNFT.json`);
const MetaDataURIContract = require(`./${env}/MetaDataURI.json`);

const SoulBondTokenAbi = TokenData['abi'];
const SoulBondTokenBytecode = TokenData['bytecode'];

let CIFIGIVEWhitelistAddress = CIFIGIVEWhitelistData['address']
let CIFIGIVEWhitelistABI = CIFIGIVEWhitelistData['abi'];
let CIFIGIVEWhitelist = new web3.eth.Contract(CIFIGIVEWhitelistABI, CIFIGIVEWhitelistAddress);

let GreenCrossWhitelistAddress = GreenCrossWhitelistData['address']
let GreenCrossWhitelistABI = GreenCrossWhitelistData['abi'];
let GreenCrossWhitelist = new web3.eth.Contract(GreenCrossWhitelistABI, GreenCrossWhitelistAddress);

let AdminWhitelistAddress = AdminWhitelistData['address']
let AdminWhitelistABI = AdminWhitelistData['abi'];
let AdminWhitelist = new web3.eth.Contract(AdminWhitelistABI, AdminWhitelistAddress);

let SubscriptionAddress = SubscriptionData['address']
let SubscriptionABI = SubscriptionData['abi'];
let Subscription = new web3.eth.Contract(SubscriptionABI, SubscriptionAddress);

let SPVNFTAddress = SPVData['address']
let SPVNFTABI = SPVData['abi'];
let SPVNFT = new web3.eth.Contract(SPVNFTABI, SPVNFTAddress);

let MetaDataURIAddress = MetaDataURIContract['address']
let MetaDataURIABI = MetaDataURIContract['abi'];
let MetaDataURI = new web3.eth.Contract(MetaDataURIABI, MetaDataURIAddress);

module.exports = {
  SubscriptionAddress, SubscriptionABI, Subscription, AdminWhitelistAddress, AdminWhitelistABI, AdminWhitelist, SPVNFTAddress, SPVNFTABI, SPVNFT, SoulBondTokenAbi, SoulBondTokenBytecode, web3,
  MetaDataURIAddress, MetaDataURIABI, MetaDataURI, CIFIGIVEWhitelistAddress, CIFIGIVEWhitelistABI, CIFIGIVEWhitelist, GreenCrossWhitelistAddress, GreenCrossWhitelistABI, GreenCrossWhitelist
};