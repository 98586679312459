import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import { validate } from "wallet-address-validator";

import "./index.css";
import { setLoader, kycGlobal, kybGlobal, setApprovedUser, deleteUser, deleteKYB, setApprovedKYB, uploadMetadata } from "../../store/actions/Auth.js";
import {
  CIFIGIVEWhitelistAddress, CIFIGIVEWhitelist, AdminWhitelistAddress, AdminWhitelist, SPVNFTAddress, SPVNFT, SoulBondTokenAbi, SoulBondTokenBytecode,
  web3, MetaDataURI, MetaDataURIAddress, SubscriptionAddress, Subscription, GreenCrossWhitelistAddress, GreenCrossWhitelist
} from "../../store/contract"

class KYCDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registerData: [],
      registerKYBData: [],
      isLoading: true,
      publicAddress: [],
      status: [],
      mintedNFT: 0,
      firstName: [],
      lastName: [],
      gender: [],
      dateOfBirth: [],
      documentType: [],
      idNumber: [],
      nationailty: [],
      fullAddress: [],
      session: [],
      address1: "",
      address2: "",
      tokenPrice: 0,
      nftPrice: 0,
      airdropPrice: 0,
      stakePrice: 0,
      daoPrice: 0,
      airdropSubPrice: "",
      tokenSubPrice: "",
      nftSubPrice: "",
      stakeSubPrice: "",
      daoSubPrice: "",
      greenCrossWalletAddress: "",
      greenCrossBlacklistWalletAddress: "",
      cifiGiveWalletAddress: "",
      cifiGiveBlacklistWalletAddress: "",
      baseURIPrice: 0,
    };
  }

  async componentDidMount() {
    await this.getTransactionData();
  }

  componentDidUpdate(prevprops, PrevState) {
    let { kycGData, kybGData } = this.props;
    if (prevprops.kycGData !== kycGData) this.setState({ registerData: kycGData });
    else if (prevprops.kybGData !== kybGData) this.setState({ registerKYBData: kybGData });
  }

  getTransactionData = async () => {
    try {
      this.props.setLoader({
        message: "Loading Data ...",
        status: true,
      });

      let tokenPrice = await Subscription.methods.tokenSubscriptionPrice().call();
      tokenPrice = web3.utils.fromWei(tokenPrice, "ether");
      let nftPrice = await Subscription.methods.nftSubscriptionPrice().call();
      nftPrice = web3.utils.fromWei(nftPrice, "ether");
      let airdropPrice = await Subscription.methods.airdropSubscriptionPrice().call();
      airdropPrice = web3.utils.fromWei(airdropPrice, "ether");
      let stakePrice = await Subscription.methods.stakeSubscriptionPrice().call();
      stakePrice = web3.utils.fromWei(stakePrice, "ether");
      let daoPrice = await Subscription.methods.daoSubscriptionPrice().call();
      daoPrice = web3.utils.fromWei(daoPrice, "ether");

      let tMinted = await SPVNFT.methods.totalSupply().call();
      this.setState({ mintedNFT: parseInt(tMinted), tokenPrice, nftPrice, airdropPrice, stakePrice, daoPrice });
      await this.props.kycGlobal();
      await this.props.kybGlobal();
    } catch (error) {
      this.props.setLoader({ status: false });
    }
  }

  copiedAddress = () => EventBus.publish("success", "Wallet Address Copied");
  copiedTransaction = () => EventBus.publish("success", "Transaction Hash Copied");

  approvedUser = async (item) => {
    try {

      let publicAddress = item['publicAddress'];
      let firstName = item['firstName'] ? item['firstName'] : "--";
      let lastName = item['lastName'] ? item['lastName'] : "--";
      let fullAddress = item['fullAddress'] ? item['fullAddress'] : "--";
      let gender = item['gender'] ? item['gender'] : "--";
      let nationality = item['nationality'] ? item['nationality'] : "--";
      let dateOfBirth = item['dateOfBirth'] ? item['dateOfBirth'] : "--";
      let documentType = item['documentType'] ? item['documentType'] : "--";
      let idNumber = item['idNumber'] ? item['idNumber'] : "--";
      this.props.setLoader({ message: 'Token Deploying Please Wait...', status: true });
      let from = (await web3.currentProvider.enable())[0];
      let contract = new web3.eth.Contract(SoulBondTokenAbi);
      let deploy = await contract.deploy({
        data: SoulBondTokenBytecode,
        arguments: [publicAddress, firstName, lastName, fullAddress, gender, nationality, dateOfBirth, documentType, idNumber],
        gas: 10000000,
      });

      await deploy
        .send({ from })
        .on("transactionHash", (hash) =>
          console.log(`************** deploy contract hash = ${hash}`)
        )
        .on("receipt", async (receipt) => {
          console.log(`************** deploy contract hash = `, receipt['contractAddress'])
          this.props.setApprovedUser({ _id: item['_id'], soulbound: receipt['contractAddress'] });
          // this.props.setLoader({ message: 'Collections Deploying Please Wait...', status: false });
        });
    } catch (error) {
      console.log("********************** error", error);
      this.props.setLoader({ status: false });
      return EventBus.publish('error', error);
    }
  }

  approvedKybUser = async (item) => {
    try {
      let { mintedNFT } = this.state;
      let publicAddress = item['publicAddress'];
      let deployer = (await web3.currentProvider.enable())[0];
      let isMinter = await SPVNFT.methods.isMinter(deployer).call();
      if (!isMinter) return EventBus.publish("error", `Only super admin can mint SPV NFT!`);
      this.props.setLoader({ message: 'Minting SPV NFT...', status: true });
      await web3.eth
        .sendTransaction({
          from: deployer,
          value: 0,
          to: SPVNFTAddress,
          gas: 5000000,
          data: SPVNFT.methods
            .mint(publicAddress, 1)
            .encodeABI(),
        })
        .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
        .on('receipt', async receipt => {
          await this.props.uploadMetadata({ "id": parseInt(mintedNFT + 1) });
          EventBus.publish("success", `SPV NFT Minted Successfully!`);
          this.props.setLoader({ message: 'Approving KYB Please Wait...', status: true });
          this.props.setApprovedKYB({ _id: item['_id'] });
          this.props.setLoader({ status: false });
        });
    } catch (error) {
      this.props.setLoader({ status: false });
      return EventBus.publish('error', error.message);
    }
  }

  deleteItem = async (item) => {
    this.props.setLoader({ message: 'User Delete Please Wait...', status: true });
    this.props.deleteUser({ _id: item['_id'] });
  }

  deleteKybItem = async (item) => {
    this.props.setLoader({ message: 'Deleting KYB Please Wait...', status: true });
    this.props.deleteKYB({ _id: item['_id'] });
  }

  handleChange = async (e) => {
    e.preventDefault();
    if ([e.target.name] == "address1") {
      if (e.target.value === "") {
        this.setState({ address1: "" });
      } else {
        let input = e.target.value;
        let output = input.substring(0, 3); // checks first three char of address
        if (output == "xdc") {
          let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
          this.setState({ address1: result });
          if (validate(result, "ETH")) {
            var code = await web3.eth.getCode(result.toLowerCase());
            if (code !== "0x") {
              EventBus.publish("error", `Invalid Wallet Address!`);
              this.setState({ address1: "" });
              return;
            }
          } else {
            EventBus.publish("error", `Invalid Wallet Address`);
            this.setState({ address1: "" });
            return;
          }
        } else {
          this.setState({ address1: e.target.value });
          if (validate(e.target.value, "ETH")) {
            var code = await web3.eth.getCode(e.target.value.toLowerCase());
            if (code !== "0x") {
              EventBus.publish("error", `Invalid Wallet Address!`);
              this.setState({ address1: "" });
              return;
            }
          } else {
            EventBus.publish("error", `Invalid Wallet Address`);
            this.setState({ address1: "" });
            return;
          }
        }
      }
    } else if ([e.target.name] == "address2") {
      if (e.target.value === "") {
        this.setState({ address2: "" });
      } else {
        let input = e.target.value;
        let output = input.substring(0, 3); // checks first three char of address
        if (output == "xdc") {
          let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
          this.setState({ address2: result });
          if (validate(result, "ETH")) {
            var code = await web3.eth.getCode(result.toLowerCase());
            if (code !== "0x") {
              EventBus.publish("error", `Invalid Wallet Address!`);
              this.setState({ address2: "" });
              return;
            }
          } else {
            EventBus.publish("error", `Invalid Wallet Address`);
            this.setState({ address2: "" });
            return;
          }
        } else {
          this.setState({ address2: e.target.value });
          if (validate(e.target.value, "ETH")) {
            var code = await web3.eth.getCode(e.target.value.toLowerCase());
            if (code !== "0x") {
              EventBus.publish("error", `Invalid Wallet Address!`);
              this.setState({ address2: "" });
              return;
            }
          } else {
            EventBus.publish("error", `Invalid Wallet Address`);
            this.setState({ address2: "" });
            return;
          }
        }
      }
    } else if ([e.target.name] == "cifiGiveWalletAddress") {
      if (e.target.value === "") {
        this.setState({ cifiGiveWalletAddress: "" });
      } else {
        let input = e.target.value;
        let output = input.substring(0, 3); // checks first three char of address
        if (output == "xdc") {
          let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
          this.setState({ cifiGiveWalletAddress: result });
          if (validate(result, "ETH")) {
            var code = await web3.eth.getCode(result.toLowerCase());
            if (code !== "0x") {
              EventBus.publish("error", `Invalid Wallet Address!`);
              this.setState({ cifiGiveWalletAddress: "" });
              return;
            }
          } else {
            EventBus.publish("error", `Invalid Wallet Address`);
            this.setState({ cifiGiveWalletAddress: "" });
            return;
          }
        } else {
          this.setState({ cifiGiveWalletAddress: e.target.value });
          if (validate(e.target.value, "ETH")) {
            var code = await web3.eth.getCode(e.target.value.toLowerCase());
            if (code !== "0x") {
              EventBus.publish("error", `Invalid Wallet Address!`);
              this.setState({ cifiGiveWalletAddress: "" });
              return;
            }
          } else {
            EventBus.publish("error", `Invalid Wallet Address`);
            this.setState({ cifiGiveWalletAddress: "" });
            return;
          }
        }
      }
    } else if ([e.target.name] == "cifiGiveBlacklistWalletAddress") {
      if (e.target.value === "") {
        this.setState({ cifiGiveBlacklistWalletAddress: "" });
      } else {
        let input = e.target.value;
        let output = input.substring(0, 3); // checks first three char of address
        if (output == "xdc") {
          let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
          this.setState({ cifiGiveBlacklistWalletAddress: result });
          if (validate(result, "ETH")) {
            var code = await web3.eth.getCode(result.toLowerCase());
            if (code !== "0x") {
              EventBus.publish("error", `Invalid Wallet Address!`);
              this.setState({ cifiGiveBlacklistWalletAddress: "" });
              return;
            }
          } else {
            EventBus.publish("error", `Invalid Wallet Address`);
            this.setState({ cifiGiveBlacklistWalletAddress: "" });
            return;
          }
        } else {
          this.setState({ cifiGiveBlacklistWalletAddress: e.target.value });
          if (validate(e.target.value, "ETH")) {
            var code = await web3.eth.getCode(e.target.value.toLowerCase());
            if (code !== "0x") {
              EventBus.publish("error", `Invalid Wallet Address!`);
              this.setState({ cifiGiveBlacklistWalletAddress: "" });
              return;
            }
          } else {
            EventBus.publish("error", `Invalid Wallet Address`);
            this.setState({ cifiGiveBlacklistWalletAddress: "" });
            return;
          }
        }
      }
    } else if ([e.target.name] == "greenCrossWalletAddress") {
      if (e.target.value === "") {
        this.setState({ greenCrossWalletAddress: "" });
      } else {
        let input = e.target.value;
        let output = input.substring(0, 3); // checks first three char of address
        if (output == "xdc") {
          let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
          this.setState({ greenCrossWalletAddress: result });
          if (validate(result, "ETH")) {
            var code = await web3.eth.getCode(result.toLowerCase());
            if (code !== "0x") {
              EventBus.publish("error", `Invalid Wallet Address!`);
              this.setState({ greenCrossWalletAddress: "" });
              return;
            }
          } else {
            EventBus.publish("error", `Invalid Wallet Address`);
            this.setState({ greenCrossWalletAddress: "" });
            return;
          }
        } else {
          this.setState({ greenCrossWalletAddress: e.target.value });
          if (validate(e.target.value, "ETH")) {
            var code = await web3.eth.getCode(e.target.value.toLowerCase());
            if (code !== "0x") {
              EventBus.publish("error", `Invalid Wallet Address!`);
              this.setState({ greenCrossWalletAddress: "" });
              return;
            }
          } else {
            EventBus.publish("error", `Invalid Wallet Address`);
            this.setState({ greenCrossWalletAddress: "" });
            return;
          }
        }
      }
    } else if ([e.target.name] == "greenCrossBlacklistWalletAddress") {
      if (e.target.value === "") {
        this.setState({ greenCrossBlacklistWalletAddress: "" });
      } else {
        let input = e.target.value;
        let output = input.substring(0, 3); // checks first three char of address
        if (output == "xdc") {
          let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
          this.setState({ greenCrossBlacklistWalletAddress: result });
          if (validate(result, "ETH")) {
            var code = await web3.eth.getCode(result.toLowerCase());
            if (code !== "0x") {
              EventBus.publish("error", `Invalid Wallet Address!`);
              this.setState({ greenCrossBlacklistWalletAddress: "" });
              return;
            }
          } else {
            EventBus.publish("error", `Invalid Wallet Address`);
            this.setState({ greenCrossBlacklistWalletAddress: "" });
            return;
          }
        } else {
          this.setState({ greenCrossBlacklistWalletAddress: e.target.value });
          if (validate(e.target.value, "ETH")) {
            var code = await web3.eth.getCode(e.target.value.toLowerCase());
            if (code !== "0x") {
              EventBus.publish("error", `Invalid Wallet Address!`);
              this.setState({ greenCrossBlacklistWalletAddress: "" });
              return;
            }
          } else {
            EventBus.publish("error", `Invalid Wallet Address`);
            this.setState({ greenCrossBlacklistWalletAddress: "" });
            return;
          }
        }
      }
    } else if ([e.target.name] == "airdropSubPrice") {
      if (parseFloat(e.target.value) > 0) {
        this.setState({ airdropSubPrice: parseFloat(e.target.value) });
      } else {
        this.setState({ airdropSubPrice: "" });
      }
    } else if ([e.target.name] == "tokenSubPrice") {
      if (parseFloat(e.target.value) > 0) {
        this.setState({ tokenSubPrice: parseFloat(e.target.value) });
      } else {
        this.setState({ tokenSubPrice: "" });
      }
    } else if ([e.target.name] == "nftSubPrice") {
      if (parseFloat(e.target.value) > 0) {
        this.setState({ nftSubPrice: parseFloat(e.target.value) });
      } else {
        this.setState({ nftSubPrice: "" });
      }
    } else if ([e.target.name] == "stakeSubPrice") {
      if (parseFloat(e.target.value) > 0) {
        this.setState({ stakeSubPrice: parseFloat(e.target.value) });
      } else {
        this.setState({ stakeSubPrice: "" });
      }
    } else if ([e.target.name] == "daoSubPrice") {
      if (parseFloat(e.target.value) > 0) {
        this.setState({ daoSubPrice: parseFloat(e.target.value) });
      } else {
        this.setState({ daoSubPrice: "" });
      }
    }
  }

  whitelistAddress = async (e) => {
    try {
      e.preventDefault();
      let { address1 } = this.state;
      let deployer = (await web3.currentProvider.enable())[0];

      if (address1 == "") {
        EventBus.publish("error", `Please enter the address`);
        return;
      }

      let isApprove = await (AdminWhitelist.methods.isWhitelist(address1).call());

      if (isApprove == true) {
        EventBus.publish("error", `Address already approved`);
        return;
      } else {
        this.props.setLoader({ message: 'Whitelisting Address...', status: true });
        await web3.eth
          .sendTransaction({
            from: deployer,
            value: 0,
            to: AdminWhitelistAddress,
            gas: 5000000,
            data: AdminWhitelist.methods
              .addWhitelist(address1)
              .encodeABI(),
          })
          .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
          .on('receipt', async receipt => {
            EventBus.publish("success", `Address Whitelisted Successfully!`);
            this.props.setLoader({ status: false });
            this.setState({ address1: "" });
          });
      }
    } catch (error) {
      console.log("********************** error", error);
      this.props.setLoader({ status: false });
      this.setState({ address1: "" });
      return EventBus.publish("error", "Whitelisting Address Failed");
    }
  }

  blacklistAddress = async (e) => {
    try {
      e.preventDefault();
      let { address2 } = this.state;
      let deployer = (await web3.currentProvider.enable())[0];
      if (address2 == "") {
        EventBus.publish("error", `Please enter the address`);
        return;
      }

      let isApprove = await (AdminWhitelist.methods.isWhitelist(address2).call());

      if (isApprove == false) {
        EventBus.publish("error", `Address is not approved`);
        return;
      } else {
        this.props.setLoader({ message: 'Blacklisting Address...', status: true });
        await web3.eth
          .sendTransaction({
            from: deployer,
            value: 0,
            to: AdminWhitelistAddress,
            gas: 5000000,
            data: AdminWhitelist.methods
              .removeWhitelist(address2)
              .encodeABI(),
          })
          .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
          .on('receipt', async receipt => {
            EventBus.publish("success", `Address Blacklisted Successfully!`);
            this.props.setLoader({ status: false });
            this.setState({ address2: "" });
          });
      }
    } catch (error) {
      console.log("********************** error", error);
      this.props.setLoader({ status: false });
      this.setState({ address2: "" });
      return EventBus.publish("error", "Blacklisting Address Failed");
    }
  }

  updateTokenSub = async (e) => {
    try {
      let { tokenSubPrice } = this.state;
      let deployer = (await web3.currentProvider.enable())[0];

      if (tokenSubPrice == "") {
        EventBus.publish("error", `Please enter token subscription price`);
        return;
      }

      if (parseFloat(tokenSubPrice) <= 0) {
        EventBus.publish("error", `Please enter token subscription price`);
        return;
      }

      this.props.setLoader({ message: 'Updating Token Subscription Price...', status: true });

      tokenSubPrice = web3.utils.toWei(tokenSubPrice.toString(), "ether");

      await web3.eth
        .sendTransaction({
          from: deployer,
          value: 0,
          to: SubscriptionAddress,
          gas: 5000000,
          data: Subscription.methods
            .setPriceToken(tokenSubPrice)
            .encodeABI(),
        })
        .on('transactionHash', hash => console.log(`************** Tx hash = ${hash}`))
        .on('receipt', async receipt => {
          EventBus.publish("success", `Updated Token Subscription Successfully!`);
          this.getTransactionData();
          this.props.setLoader({ status: false });
          this.setState({ tokenSubPrice: "" });
        });

    } catch (e) {
      console.log("Error :: ", e);
      this.props.setLoader({ status: false });
      this.setState({ tokenSubPrice: "" });
      return EventBus.publish("error", "Token Subscription Update Failed");
    }
  }

  updateNFTSub = async (e) => {
    try {
      let { nftSubPrice } = this.state;
      let deployer = (await web3.currentProvider.enable())[0];

      if (nftSubPrice == "") {
        EventBus.publish("error", `Please enter NFT subscription price`);
        return;
      }

      if (parseFloat(nftSubPrice) <= 0) {
        EventBus.publish("error", `Please enter NFT subscription price`);
        return;
      }

      this.props.setLoader({ message: 'Updating NFT Subscription Price...', status: true });

      nftSubPrice = web3.utils.toWei(nftSubPrice.toString(), "ether");

      await web3.eth
        .sendTransaction({
          from: deployer,
          value: 0,
          to: SubscriptionAddress,
          gas: 5000000,
          data: Subscription.methods
            .setPriceNft(nftSubPrice)
            .encodeABI(),
        })
        .on('transactionHash', hash => console.log(`************** Tx hash = ${hash}`))
        .on('receipt', async receipt => {
          EventBus.publish("success", `Updated NFT Subscription Successfully!`);
          this.getTransactionData();
          this.props.setLoader({ status: false });
          this.setState({ nftSubPrice: "" });
        });

    } catch (e) {
      console.log("Error :: ", e);
      this.props.setLoader({ status: false });
      this.setState({ nftSubPrice: "" });
      return EventBus.publish("error", "NFT Subscription Update Failed");
    }
  }

  updateAirdropSub = async (e) => {
    try {
      let { airdropSubPrice } = this.state;
      let deployer = (await web3.currentProvider.enable())[0];

      if (airdropSubPrice == "") {
        EventBus.publish("error", `Please enter Airdrop subscription price`);
        return;
      }

      if (parseFloat(airdropSubPrice) <= 0) {
        EventBus.publish("error", `Please enter Airdrop subscription price`);
        return;
      }

      this.props.setLoader({ message: 'Updating Airdrop Subscription Price...', status: true });

      airdropSubPrice = web3.utils.toWei(airdropSubPrice.toString(), "ether");

      await web3.eth
        .sendTransaction({
          from: deployer,
          value: 0,
          to: SubscriptionAddress,
          gas: 5000000,
          data: Subscription.methods
            .setPriceAirdrop(airdropSubPrice)
            .encodeABI(),
        })
        .on('transactionHash', hash => console.log(`************** Tx hash = ${hash}`))
        .on('receipt', async receipt => {
          EventBus.publish("success", `Updated Airdrop Subscription Successfully!`);
          this.getTransactionData();
          this.props.setLoader({ status: false });
          this.setState({ airdropSubPrice: "" });
        });

    } catch (e) {
      console.log("Error :: ", e);
      this.props.setLoader({ status: false });
      this.setState({ airdropSubPrice: "" });
      return EventBus.publish("error", "Airdrop Subscription Update Failed");
    }
  }

  updateStakeSub = async (e) => {
    try {
      let { stakeSubPrice } = this.state;
      let deployer = (await web3.currentProvider.enable())[0];

      if (stakeSubPrice == "") {
        EventBus.publish("error", `Please enter Stake subscription price`);
        return;
      }

      if (parseFloat(stakeSubPrice) <= 0) {
        EventBus.publish("error", `Please enter Stake subscription price`);
        return;
      }

      this.props.setLoader({ message: 'Updating Stake Subscription Price...', status: true });

      stakeSubPrice = web3.utils.toWei(stakeSubPrice.toString(), "ether");

      await web3.eth
        .sendTransaction({
          from: deployer,
          value: 0,
          to: SubscriptionAddress,
          gas: 5000000,
          data: Subscription.methods
            .setPriceStake(stakeSubPrice)
            .encodeABI(),
        })
        .on('transactionHash', hash => console.log(`************** Tx hash = ${hash}`))
        .on('receipt', async receipt => {
          EventBus.publish("success", `Updated Stake Subscription Successfully!`);
          this.getTransactionData();
          this.props.setLoader({ status: false });
          this.setState({ stakeSubPrice: "" });
        });

    } catch (e) {
      console.log("Error :: ", e);
      this.props.setLoader({ status: false });
      this.setState({ stakeSubPrice: "" });
      return EventBus.publish("error", "Stake Subscription Update Failed");
    }
  }

  updateDAOSub = async (e) => {
    try {
      let { daoSubPrice } = this.state;
      let deployer = (await web3.currentProvider.enable())[0];

      if (daoSubPrice == "") {
        EventBus.publish("error", `Please enter DAO subscription price`);
        return;
      }

      if (parseFloat(daoSubPrice) <= 0) {
        EventBus.publish("error", `Please enter DAO subscription price`);
        return;
      }

      this.props.setLoader({ message: 'Updating DAO Subscription Price...', status: true });

      daoSubPrice = web3.utils.toWei(daoSubPrice.toString(), "ether");

      await web3.eth
        .sendTransaction({
          from: deployer,
          value: 0,
          to: SubscriptionAddress,
          gas: 5000000,
          data: Subscription.methods
            .setPriceDAO(daoSubPrice)
            .encodeABI(),
        })
        .on('transactionHash', hash => console.log(`************** Tx hash = ${hash}`))
        .on('receipt', async receipt => {
          EventBus.publish("success", `Updated DAO Subscription Successfully!`);
          this.getTransactionData();
          this.props.setLoader({ status: false });
          this.setState({ daoSubPrice: "" });
        });

    } catch (e) {
      console.log("Error :: ", e);
      this.props.setLoader({ status: false });
      this.setState({ daoSubPrice: "" });
      return EventBus.publish("error", "DAO Subscription Update Failed");
    }
  }

  submitCifiGiveApprove = async (e) => {
    e.preventDefault();
    try {
      let { cifiGiveWalletAddress } = this.state;

      let publicAddress = (await web3.currentProvider.enable())[0];

      if (cifiGiveWalletAddress == "") {
        EventBus.publish("error", `Please enter the address`);
        return;
      }

      /** Approval **/
      let isSuperAdmin = await (CIFIGIVEWhitelist.methods.isSuperAdmin(publicAddress).call({ from: publicAddress }));

      if (isSuperAdmin == true) {
        let isApprove = await (CIFIGIVEWhitelist.methods.isWhitelist(cifiGiveWalletAddress).call());

        if (isApprove == true) {
          EventBus.publish("error", `Address already approved`);
          return;

        } else {
          this.props.setLoader({
            message: "Approval in Progress...",
            status: true,
          });

          await web3.eth
            .sendTransaction({
              from: publicAddress,
              value: 0,
              to: CIFIGIVEWhitelistAddress,
              gas: 2000000,
              data: CIFIGIVEWhitelist.methods
                .addWhitelist(cifiGiveWalletAddress)
                .encodeABI(),
            })
            .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
            .on("receipt", async (receipt) => {
              this.setState({ cifiGiveWalletAddress: "" });
              this.props.setLoader({ status: false });
              EventBus.publish("success", `Approved Successfully`);
            });
        }
      } else {
        EventBus.publish("error", `Please approve through Super Admin Address!`);
        return;
      }

    } catch (e) {
      console.log("********Error = ", e);
      this.props.setLoader({
        message: "Transfer Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable To Execute Tx`);
    }
  };

  submitCifiGiveBlacklist = async (e) => {
    e.preventDefault();
    try {
      let { cifiGiveBlacklistWalletAddress } = this.state;

      let publicAddress = (await web3.currentProvider.enable())[0];

      if (cifiGiveBlacklistWalletAddress == "") {
        EventBus.publish("error", `Please enter the address`);
        return;
      }

      /** Approval **/
      let isSuperAdmin = await (CIFIGIVEWhitelist.methods.isSuperAdmin(publicAddress).call({ from: publicAddress }));

      if (isSuperAdmin == true) {
        /** Blacklist **/
        let isApprove = await (CIFIGIVEWhitelist.methods.isWhitelist(cifiGiveBlacklistWalletAddress).call());

        if (isApprove == false) {
          EventBus.publish("error", `Address is not approved`);
          return;
        } else {
          this.props.setLoader({
            message: "Blacklist in Progress...",
            status: true,
          });

          await web3.eth
            .sendTransaction({
              from: publicAddress,
              value: 0,
              to: CIFIGIVEWhitelistAddress,
              gas: 2000000,
              data: CIFIGIVEWhitelist.methods
                .removeWhitelist(cifiGiveBlacklistWalletAddress)
                .encodeABI(),
            })
            .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
            .on("receipt", async (receipt) => {
              this.setState({ cifiGiveBlacklistWalletAddress: "" });
              this.props.setLoader({ status: false });
              EventBus.publish("success", `Blacklisted Successfully`);
            });
        }
      } else {
        EventBus.publish("error", `Please approve through Super Admin Address!`);
        return;
      }
    } catch (e) {
      console.log("********Error = ", e);
      this.props.setLoader({
        message: "Blacklist Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable To Execute Tx`);
    }
  };

  submitGreenCrossApprove = async (e) => {
    e.preventDefault();
    try {
      let { greenCrossWalletAddress } = this.state;

      let publicAddress = (await web3.currentProvider.enable())[0];

      if (greenCrossWalletAddress == "") {
        EventBus.publish("error", `Please enter the address`);
        return;
      }

      /** Approval **/
      let isSuperAdmin = await (GreenCrossWhitelist.methods.isSuperAdmin(publicAddress).call({ from: publicAddress }));

      if (isSuperAdmin == true) {
        let isApprove = await (GreenCrossWhitelist.methods.isWhitelist(greenCrossWalletAddress).call());

        if (isApprove == true) {
          EventBus.publish("error", `Address already approved`);
          return;

        } else {
          this.props.setLoader({
            message: "Approval in Progress...",
            status: true,
          });

          await web3.eth
            .sendTransaction({
              from: publicAddress,
              value: 0,
              to: GreenCrossWhitelistAddress,
              gas: 2000000,
              data: GreenCrossWhitelist.methods
                .addWhitelist(greenCrossWalletAddress)
                .encodeABI(),
            })
            .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
            .on("receipt", async (receipt) => {
              this.setState({ greenCrossWalletAddress: "" });
              this.props.setLoader({ status: false });
              EventBus.publish("success", `Approved Successfully`);
            });
        }
      } else {
        EventBus.publish("error", `Please approve through Green Cross Super Admin Address!`);
        return;
      }

    } catch (e) {
      console.log("********Error = ", e);
      this.props.setLoader({
        message: "Transfer Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable To Execute Tx`);
    }
  };

  submitGreenCrossBlacklist = async (e) => {
    e.preventDefault();
    try {
      let { greenCrossBlacklistWalletAddress } = this.state;

      let publicAddress = (await web3.currentProvider.enable())[0];

      if (greenCrossBlacklistWalletAddress == "") {
        EventBus.publish("error", `Please enter the address`);
        return;
      }

      // /** Approval **/
      let isSuperAdmin = await (GreenCrossWhitelist.methods.isSuperAdmin(publicAddress).call({ from: publicAddress }));

      if (isSuperAdmin == true) {
        /** Blacklist **/
        let isApprove = await (GreenCrossWhitelist.methods.isWhitelist(greenCrossBlacklistWalletAddress).call());

        if (isApprove == false) {
          EventBus.publish("error", `Address is not approved`);
          return;
        } else {
          this.props.setLoader({
            message: "Blacklist in Progress...",
            status: true,
          });

          await web3.eth
            .sendTransaction({
              from: publicAddress,
              value: 0,
              to: GreenCrossWhitelistAddress,
              gas: 2000000,
              data: GreenCrossWhitelist.methods
                .removeWhitelist(greenCrossBlacklistWalletAddress)
                .encodeABI(),
            })
            .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
            .on("receipt", async (receipt) => {
              this.setState({ greenCrossBlacklistWalletAddress: "" });
              this.props.setLoader({ status: false });
              EventBus.publish("success", `Blacklisted Successfully`);
            });
        }
      } else {
        EventBus.publish("error", `Please approve through Green Cross Super Admin Address!`);
        return;
      }
    } catch (e) {
      console.log("********Error = ", e);
      this.props.setLoader({
        message: "Blacklist Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable To Execute Tx`);
    }
  };

  updateBaseURIPrice = async (e) => {
    e.preventDefault();
    try {
      let { baseURIPrice } = this.state;

      let publicAddress = (await web3.currentProvider.enable())[0];

      if (baseURIPrice == "") {
        EventBus.publish("error", `Please enter the price`);
        return;
      }

      baseURIPrice = await web3.utils.toWei(baseURIPrice.toString(), 'ether');

      this.props.setLoader({
        message: "Transaction...",
        status: true,
      });

      await web3.eth
        .sendTransaction({
          from: publicAddress,
          value: 0,
          to: MetaDataURIAddress,
          gas: 2000000,
          data: MetaDataURI.methods
            .setSubscriptionPrice(baseURIPrice)
            .encodeABI(),
        })
        .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
        .on("receipt", async (receipt) => {
          this.setState({ cifiGiveBlacklistWalletAddress: "" });
          this.props.setLoader({ status: false });
          EventBus.publish("success", `Price Set Successfully`);
        });

    } catch (e) {
      console.log("********Error = ", e);
      this.props.setLoader({
        message: "Blacklist Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable To Execute Tx`);
    }
  };

  render() {
    let { registerData, registerKYBData, address1, address2, tokenPrice, nftPrice, airdropPrice, stakePrice, daoPrice, airdropSubPrice,
      tokenSubPrice, nftSubPrice, stakeSubPrice, daoSubPrice, cifiGiveWalletAddress, cifiGiveBlacklistWalletAddress, baseURIPrice,
      greenCrossWalletAddress, greenCrossBlacklistWalletAddress } = this.state;

    return (
      <>
        <div className="tab-pane fade show active" id="customer" role="tabpanel" aria-labelledby="customer-tab">
          <div className="super-admin-head">
            <h1>KYC Approved</h1>

            <div className="super-admin">
              <span>Super Admin X</span>


            </div>
          </div>
          <div className="customer-table">
            <table cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th id="status">Status</th>
                  <th id="publicAddress">Wallet Address</th>
                  <th id="publicAddress">Email</th>
                  <th id="firstName">First name</th>
                  <th id="lastName">Last Name</th>
                  <th id="gender">Gender</th>
                  <th id="dateOfBirth">Date of birth</th>
                  <th id="documentType">Document Type</th>
                  <th id="idNumber">Document ID</th>
                  <th id="nationailty">Nationality</th>
                  <th id="fullAddress">Address</th>
                  <th id="status">Status</th>
                  <th id="delete">Delete</th>
                </tr>
              </thead>
              <tbody>
                {registerData.filter(item => item.status == "approved").map((item) =>
                  <tr>

                    <td>{item['status'] ? item['status'] : "-"}</td>
                    <td>{item['publicAddress'] ? item['publicAddress'] : "-"}</td>
                    <td>{item['email'] ? item['email'] : "-"}</td>
                    <td>{item['firstName'] ? item['firstName'] : "-"}</td>
                    <td>{item['lastName'] ? item['lastName'] : "-"}</td>
                    <td>{item['gender'] ? item['gender'] : "-"}</td>
                    <td>{item['dateOfBirth'] ? item['dateOfBirth'] : "-"}</td>
                    <td>{item['documentType'] ? item['documentType'] : "-"}</td>
                    <td>{item['idNumber'] ? item['idNumber'] : "-"}</td>
                    <td>{item['nationailty'] ? item['nationailty'] : "-"}</td>
                    <td>{item['fullAddress'] ? item['fullAddress'] : "-"}</td>
                    <td>
                      {item['status'] == "approved" ?
                        <button onClick={() => this.approvedUser(item)}>Approved</button>
                        :
                        item['status']
                      }
                    </td>
                    <td>
                      <button onClick={() => this.deleteItem(item)}>Delete</button>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

        </div>

        <div className="tab-pane fade" id="customerCreted" role="tabpanel" aria-labelledby="customerCreted-tab">
          <div className="super-admin-head">
            <h1>KYC Created</h1>

            <div className="super-admin">
              <span>Super Admin X</span>


            </div>
          </div>
          <div className="customer-table">
            <table cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th id="status">Status</th>
                  <th id="publicAddress">Wallet Address</th>
                  <th id="email">Email</th>
                  <th id="firstName">First name</th>
                  <th id="lastName">Last Name</th>
                  <th id="gender">Gender</th>
                  <th id="dateOfBirth">Date of birth</th>
                  <th id="documentType">Document Type</th>
                  <th id="idNumber">Document ID</th>
                  <th id="nationailty">Nationality</th>
                  <th id="fullAddress">Address</th>
                  <th id="status">Status</th>
                </tr>
              </thead>
              <tbody>
                {registerData.filter(item => item.status == "created").map((item) =>
                  <tr>

                    <td>{item['status'] ? item['status'] : "-"}</td>
                    <td>{item['publicAddress'] ? item['publicAddress'] : "-"}</td>
                    <td>{item['email'] ? item['email'] : "-"}</td>
                    <td>{item['firstName'] ? item['firstName'] : "-"}</td>
                    <td>{item['lastName'] ? item['lastName'] : "-"}</td>
                    <td>{item['gender'] ? item['gender'] : "-"}</td>
                    <td>{item['dateOfBirth'] ? item['dateOfBirth'] : "-"}</td>
                    <td>{item['documentType'] ? item['documentType'] : "-"}</td>
                    <td>{item['idNumber'] ? item['idNumber'] : "-"}</td>
                    <td>{item['nationailty'] ? item['nationailty'] : "-"}</td>
                    <td>{item['fullAddress'] ? item['fullAddress'] : "-"}</td>
                    <td>
                      <button onClick={() => this.deleteItem(item)}>Delete</button>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

        </div>

        <div className="tab-pane fade" id="customerApproved" role="tabpanel" aria-labelledby="customerApproved-tab">
          <div className="super-admin-head">
            <h1>KYC Approved by Admin</h1>

            <div className="super-admin">
              <span>Super Admin X</span>


            </div>
          </div>
          <div className="customer-table">
            <table cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th id="status">Status</th>
                  <th id="publicAddress">Wallet Address</th>
                  <th id="email">Email</th>
                  <th id="firstName">First name</th>
                  <th id="lastName">Last Name</th>
                  <th id="gender">Gender</th>
                  <th id="dateOfBirth">Date of birth</th>
                  <th id="documentType">Document Type</th>
                  <th id="idNumber">Document ID</th>
                  <th id="nationailty">Nationality</th>
                  <th id="fullAddress">Address</th>
                  <th id="status">Status</th>
                </tr>
              </thead>
              <tbody>
                {registerData.filter(item => item.status == "adminApproved").map((item) =>
                  <tr>

                    <td>{item['status'] ? item['status'] : "-"}</td>
                    <td>{item['publicAddress'] ? item['publicAddress'] : "-"}</td>
                    <td>{item['email'] ? item['email'] : "-"}</td>
                    <td>{item['firstName'] ? item['firstName'] : "-"}</td>
                    <td>{item['lastName'] ? item['lastName'] : "-"}</td>
                    <td>{item['gender'] ? item['gender'] : "-"}</td>
                    <td>{item['dateOfBirth'] ? item['dateOfBirth'] : "-"}</td>
                    <td>{item['documentType'] ? item['documentType'] : "-"}</td>
                    <td>{item['idNumber'] ? item['idNumber'] : "-"}</td>
                    <td>{item['nationailty'] ? item['nationailty'] : "-"}</td>
                    <td>{item['fullAddress'] ? item['fullAddress'] : "-"}</td>
                    <td>
                      <button onClick={() => this.deleteItem(item)}>Delete</button>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

        </div>

        <div className="tab-pane fade" id="business" role="tabpanel" aria-labelledby="business-tab">
          <div className="super-admin-head">
            <h1>KYB & KYN Created</h1>

            <div className="super-admin">
              <span>Super Admin X</span>


            </div>
          </div>
          <div className="customer-table">
            <table cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th id="type">Type</th>
                  <th id="status">Status</th>
                  <th id="publicAddress">Wallet Address</th>
                  <th id="email">Email</th>
                  <th id="businessTitle">Business Title</th>
                  <th id="businessId">Business EIN</th>
                  <th id="ownerTaxId">Onwer Tax ID</th>
                  <th id="website">Website</th>
                  <th id="facebook">Facebook</th>
                  <th id="linkedIn">LinkedIn</th>
                  <th id="twitter">Twitter</th>
                  <th id="document">Document</th>
                  <th id="delete">Delete</th>
                  <th id="approve">Approve</th>
                </tr>
              </thead>
              <tbody>
                {registerKYBData.filter(item => item.kybStatus == "submitted").map((item) =>
                  <tr>
                    {
                      item['isKYN'] && item['isKYN'] == true ? (
                        <td>CIFI Give</td>
                      ) : item['isGreenCross'] && item['isGreenCross'] == true ? (
                        <td>Green Cross</td>
                      ) : (
                        <td>CIFI</td>
                      )
                    }
                    <td>{item['kybStatus'] ? item['kybStatus'] : "-"}</td>
                    <td>{item['publicAddress'] ? item['publicAddress'] : "-"}</td>
                    <td>{item['email'] ? item['email'] : "-"}</td>
                    <td>{item['title'] ? item['title'] : "-"}</td>
                    <td>{item['ein'] ? item['ein'] : "-"}</td>
                    <td>{item['ownerSocialNo'] ? item['ownerSocialNo'] : "-"}</td>
                    <td>{item['website'] ? item['website'] : "-"}</td>
                    <td>{item['facebook'] ? item['facebook'] : "-"}</td>
                    <td>{item['linkedin'] ? item['linkedin'] : "-"}</td>
                    <td>{item['twitter'] ? item['twitter'] : "-"}</td>
                    <td><a target="_blank" href={item['document'] && item['document']}>{item['document'] ? item['document'].substring(0, 15) + '...' : "-"}<img src={require('../../assets/img/edit.svg')} alt='' /></a></td>
                    <td>
                      <button onClick={() => this.deleteKybItem(item)}>Delete</button>
                    </td>
                    <td>
                      {item['kybStatus'] !== "approved" ?
                        <button onClick={() => this.approvedKybUser(item)}>Approve</button>
                        :
                        item['kybStatus']
                      }
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

        </div>

        <div className="tab-pane fade" id="businessApproved" role="tabpanel" aria-labelledby="businessApproved-tab">
          <div className="super-admin-head">
            <h1>KYB & KYN Approved by Admin</h1>

            <div className="super-admin">
              <span>Super Admin X</span>


            </div>
          </div>
          <div className="customer-table">
            <table cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th id="type">Type</th>
                  <th id="status">Status</th>
                  <th id="publicAddress">Wallet Address</th>
                  <th id="email">Email</th>
                  <th id="businessTitle">Business Title</th>
                  <th id="businessId">Business EIN</th>
                  <th id="ownerTaxId">Onwer Tax ID</th>
                  <th id="website">Website</th>
                  <th id="facebook">Facebook</th>
                  <th id="linkedIn">LinkedIn</th>
                  <th id="twitter">Twitter</th>
                  <th id="document">Document</th>
                  <th id="delete">Delete</th>
                </tr>
              </thead>
              <tbody>
                {registerKYBData.filter(item => item.kybStatus == "approved").map((item) =>
                  <tr>
                    {
                      item['isKYN'] && item['isKYN'] == true ? (
                        <td>CIFI Give</td>
                      ) : item['isGreenCross'] && item['isGreenCross'] == true ? (
                        <td>Green Cross</td>
                      ) : (
                        <td>CIFI</td>
                      )
                    }
                    <td>{item['kybStatus'] ? item['kybStatus'] : "-"}</td>
                    <td>{item['publicAddress'] ? item['publicAddress'] : "-"}</td>
                    <td>{item['email'] ? item['email'] : "-"}</td>
                    <td>{item['title'] ? item['title'] : "-"}</td>
                    <td>{item['ein'] ? item['ein'] : "-"}</td>
                    <td>{item['ownerSocialNo'] ? item['ownerSocialNo'] : "-"}</td>
                    <td>{item['website'] ? item['website'] : "-"}</td>
                    <td>{item['facebook'] ? item['facebook'] : "-"}</td>
                    <td>{item['linkedin'] ? item['linkedin'] : "-"}</td>
                    <td>{item['twitter'] ? item['twitter'] : "-"}</td>
                    <td><a target="_blank" href={item['document'] && item['document']}>{item['document'] ? item['document'].substring(0, 15) + '...' : "-"}<img src={require('../../assets/img/edit.svg')} alt='' /></a></td>
                    <td>
                      <button onClick={() => this.deleteKybItem(item)}>Delete</button>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

        </div>

        <div className="tab-pane fade" id="whitelistAddress" role="tabpanel" aria-labelledby="whitelistAddress-tab">
          <div className="super-admin-head">
            <h1>Whitelist User</h1>

            <div className="super-admin">
              <span>Super Admin X</span>
            </div>
          </div>

          <div className="form-wrap min">
            <div className="inner">
              <div className="head">
                <h2>Whitelist User</h2>
                <p>Whitelist partners to give them a bypass to CIFI platform without buying VIP NFT</p>
              </div>
              <form onSubmit={this.whitelistAddress}>
                <div className="form-group">
                  <input
                    type="text"
                    name='address1'
                    defaultValue={address1}
                    placeholder="Wallet Address"
                    onChange={this.handleChange}
                  />
                </div>

                <button type="submit">
                  Whitelist address
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="tab-pane fade" id="blacklistAddress" role="tabpanel" aria-labelledby="blacklistAddress-tab">
          <div className="super-admin-head">
            <h1>Blacklist User</h1>

            <div className="super-admin">
              <span>Super Admin X</span>
            </div>
          </div>

          <div className="form-wrap min">
            <div className="inner">
              <div className="head">
                <h2>Blacklist User</h2>
                <p>Blacklist partners to revoke their bypass access to CIFI platform without buying VIP NFT</p>
              </div>

              <form onSubmit={this.blacklistAddress}>
                <div className="form-group">
                  <input
                    type="text"
                    name='address2'
                    defaultValue={address2}
                    placeholder="Wallet Address"
                    onChange={this.handleChange}
                  />
                </div>

                <button type="submit">
                  Blacklist address
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="tab-pane fade" id="subscription" role="tabpanel" aria-labelledby="subscription-tab">
          <div className="super-admin-head">
            <h1>Subscription</h1>

            <div className="super-admin">
              <span>Super Admin X</span>
            </div>
          </div>

          <div className="form-wrap min">
            <div className="inner">
              <div className="head">
                <h2>Subscription</h2>
              </div>
              <div className="form-inner">

                <div className="wrap mb-4">
                  <div className="form-group">
                    <label>Token Subscription Price: {tokenPrice} CIFI</label>
                    <input
                      type="text"
                      name='tokenSubPrice'
                      defaultValue={tokenSubPrice}
                      placeholder="Token Subscription Price"
                      onChange={this.handleChange}
                    />
                  </div>

                  <button onClick={this.updateTokenSub}>
                    Update
                  </button>
                </div>

                <div className="wrap mb-4">
                  <div className="form-group">
                    <label>NFT Subscription Price: {nftPrice} CIFI</label>
                    <input
                      type="text"
                      name='nftSubPrice'
                      defaultValue={nftSubPrice}
                      placeholder="NFT Subscription Price"
                      onChange={this.handleChange}
                    />
                  </div>

                  <button onClick={this.updateNFTSub}>
                    Update
                  </button>
                </div>

                <div className="wrap mb-4">
                  <div className="form-group">
                    <label>Airdrop Subscription Price: {airdropPrice} CIFI</label>
                    <input
                      type="text"
                      name='airdropSubPrice'
                      defaultValue={airdropSubPrice}
                      placeholder="Airdrop Subscription Price"
                      onChange={this.handleChange}
                    />
                  </div>

                  <button onClick={this.updateAirdropSub}>
                    Update
                  </button>
                </div>

                <div className="wrap mb-4">
                  <div className="form-group">
                    <label>Stake Subscription Price: {stakePrice} CIFI</label>
                    <input
                      type="text"
                      name='stakeSubPrice'
                      defaultValue={stakeSubPrice}
                      placeholder="Stake Subscription Price"
                      onChange={this.handleChange}
                    />
                  </div>

                  <button onClick={this.updateStakeSub}>
                    Update
                  </button>
                </div>

                <div className="wrap">
                  <div className="form-group">
                    <label>DAO Subscription Price: {daoPrice} CIFI</label>
                    <input
                      type="text"
                      name='daoSubPrice'
                      defaultValue={daoSubPrice}
                      placeholder="DAP Subscription Price"
                      onChange={this.handleChange}
                    />
                  </div>

                  <button onClick={this.updateDAOSub}>
                    Update
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="tab-pane fade" id="approve-cifigive" role="tabpanel" aria-labelledby="approve-cifigive-tab">
          <div className="super-admin-head">
            <h1>Approve Cifi Give</h1>

            <div className="super-admin">
              <span>Super Admin X</span>
            </div>
          </div>

          <div className="form-wrap min">
            <div className="inner">
              <div className="head">
                <h2>Approve Cifi Give</h2>
                {/* <p>Approve Cifi Give partners to revoke their bypass access to CIFI platform without buying VIP NFT</p> */}
              </div>

              <form onSubmit={this.submitCifiGiveApprove}>
                <div className="form-group">
                  <input
                    type="text"
                    name='cifiGiveWalletAddress'
                    value={cifiGiveWalletAddress}
                    placeholder="Wallet Address"
                    onChange={this.handleChange}
                  />
                </div>

                <button type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="tab-pane fade" id="blacklistAddress-cifigive" role="tabpanel" aria-labelledby="blacklistAddress-cifigive-tab">
          <div className="super-admin-head">
            <h1>Blacklist Cifi Give</h1>

            <div className="super-admin">
              <span>Super Admin X</span>
            </div>
          </div>

          <div className="form-wrap min">
            <div className="inner">
              <div className="head">
                <h2>Blacklist Cifi Give</h2>
                {/* <p>Blacklist Cifi Give partners to revoke their bypass access to CIFI platform without buying VIP NFT</p> */}
              </div>

              <form onSubmit={this.submitCifiGiveBlacklist}>
                <div className="form-group">
                  <input
                    type="text"
                    name='cifiGiveBlacklistWalletAddress'
                    value={cifiGiveBlacklistWalletAddress}
                    placeholder="Wallet Address"
                    onChange={this.handleChange}
                  />
                </div>

                <button type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="tab-pane fade" id="approve-greencross" role="tabpanel" aria-labelledby="approve-greencross-tab">
          <div className="super-admin-head">
            <h1>Approve Green Cross</h1>

            <div className="super-admin">
              <span>Super Admin X</span>
            </div>
          </div>

          <div className="form-wrap min">
            <div className="inner">
              <div className="head">
                <h2>Approve Green Cross</h2>
              </div>

              <form onSubmit={this.submitGreenCrossApprove}>
                <div className="form-group">
                  <input
                    type="text"
                    maxLength="43"
                    name='greenCrossWalletAddress'
                    value={greenCrossWalletAddress}
                    placeholder="Wallet Address"
                    onChange={this.handleChange}
                  />
                </div>

                <button type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="tab-pane fade" id="blacklistAddress-greencross" role="tabpanel" aria-labelledby="blacklistAddress-greencross-tab">
          <div className="super-admin-head">
            <h1>Blacklist Green Cross</h1>

            <div className="super-admin">
              <span>Super Admin X</span>
            </div>
          </div>

          <div className="form-wrap min">
            <div className="inner">
              <div className="head">
                <h2>Blacklist Green Cross</h2>
              </div>

              <form onSubmit={this.submitGreenCrossBlacklist}>
                <div className="form-group">
                  <input
                    type="text"
                    maxLength="43"
                    name='greenCrossBlacklistWalletAddress'
                    value={greenCrossBlacklistWalletAddress}
                    placeholder="Wallet Address"
                    onChange={this.handleChange}
                  />
                </div>

                <button type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="tab-pane fade" id="base-url-price" role="tabpanel" aria-labelledby="base-url-pric-tab">
          <div className="super-admin-head">
            <h1>Base URI Tool</h1>

            <div className="super-admin">
              <span>Super Admin X</span>
            </div>
          </div>

          <div className="form-wrap min">
            <div className="inner">
              <div className="head">
                <h2>Base URI Tool</h2>
              </div>
              <div className="form-inner">

                <div className="wrap mb-4">
                  <div className="form-group">
                    <label>Base URI Tool Price</label>
                    <input
                      type="number"
                      name='baseURIToolPrice'
                      defaultValue={baseURIPrice}
                      placeholder="Set Base URI Price"
                      onChange={e => this.setState({ baseURIPrice: e.target.value })}
                    />
                  </div>

                  <button onClick={this.updateBaseURIPrice}>
                    Update Price
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </>

    );
  }
}

const mapDispatchToProps = {
  setLoader,
  kycGlobal,
  kybGlobal,
  setApprovedUser,
  deleteUser,
  deleteKYB,
  setApprovedKYB,
  uploadMetadata
};

const mapStateToProps = ({ Auth }) => {
  let { kycGData, kybGData } = Auth;
  return { kycGData, kybGData };
};
export default connect(mapStateToProps, mapDispatchToProps)(KYCDetails);