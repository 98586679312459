
import KYCDetails from "./views/KYCDetails/index.js";
import ApprovedKYCDetails from "./views/KYCApproved/index.js";

var routes = [
  {
    layout: "/home",
    path: "/",
    component: KYCDetails,
    hidden: true,
  },
  {
    layout: "/home",
    path: "/KYCDetails",
    name: "KYC Details",
    component: KYCDetails,
    imgUrl: "/images/details.svg",
  },
  {
    layout: "/home",
    path: "/KYCApproved",
    name: "Approved KYC Details",
    component: ApprovedKYCDetails,
    imgUrl: "/images/details.svg",
  },
];

export default routes;